import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './style.css';
import './styles/Results.css';
import { useEffect, useState } from 'react';
import ConnectionFinder from '../api/ConnectionFinder';
import Maps from './Maps';
import { useNavigate} from 'react-router';
import Cookies from './Cookies';
import Navbar from './Navbar';
import CoveredList from './CoveredList';
import Status from './Status';
import GeneralData from './GeneralData';
import Loading from './Loading';
import Lens from '../img/lens.png';
import Form from './Form';
import { changeAddress, changeCity,changeNumber} from '../actions';
import ResulTextFStyle from './ResultTexFStyle';
import FreeSoloResults from './FreeSoloResults';

const Results = (props) => {
    let city = useSelector(state => state.params.city.trim())
    let AddrInfo = useSelector(state => state.params.address)
    let number = useSelector(state => state.params.civic.trim())
    let egon = AddrInfo.egon;
    let address = AddrInfo.name; 
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [status, setStatus] = useState(     
        <div style={{height: '60vh'} }>
            <Loading style={{width: '95%', marginTop: '30px', height: 'calc(100% - 30px)', borderRadius: '13px'}}/>
        </div>
    )
    const [Formstatus, FormsetStatus] = useState( 
        <p></p>
    )
    const isEmpty = (foo) =>{
        return foo === "" || foo === "NON DEFINITO"
    }
    const emptyVar = (foo) =>{
        if (isEmpty(foo))
            return "";
        return foo;
    }
    
    // eslint-disable-next-line
    useEffect(() => {loadResults(city,address,number,egon)},[])

    async function loadResults(city,address,number,egon){
        if ((isEmpty(number) && !isEmpty(address)) || isEmpty(city) || (isEmpty(address) && !isEmpty(number)))
            navigate('/', {replace: true})
        address = emptyVar(address);
        number = emptyVar(number);
        egon = emptyVar(egon);
        await  ConnectionFinder.post("/require/results", {
            city: city,
            address: address,
            number: number,
            egon: egon,
            map: true
        }).then((response) => {
            const OF = response.data.OF;
            const FC = response.data.FC;
            const Fibre_Connect = response.data.Fibre_Connect;
            setIsLoading(false);
            dispatch(changeAddress({egon:egon, name: address, fctable: FC.fctable}))
            if( response.data === "ADDRESS NOT AVIABLE") {
                setStatus(
                    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%', paddingTop: '30px'}}>
                        <Status status={'not_connected'}/>
                        <GeneralData data={response.data.fd}/>
                        <div className="rButton" onClick = {() => navigate('/', {replace: true})}>Nuova ricerca</div>
                    </div>
                )
            }
            else if(response.data.message === 'of_not_aviable'){
                let date = response.data.piano_of_fibra;
                if(date === "") date = "Data non definita"
                setStatus(
                    
                    <div style={{paddingTop: '30px'}}>
                        <Status status={'city_not_connected'}/>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                            <table className='cityStatusTable'>
                                <tr className="tPar" style={{color: 'white'}}>
                                    <th>Comune</th>
                                    <th>Stato lavori fibra</th>
                                    <th>Data</th>
                                </tr>
                                <tr >
                                    <td width="25%" style={{backgroundColor :'white'}}>{response.data.result[0].city_name}</td>
                                    <td width="50%" style={{backgroundColor :'white'}}>{response.data.result[0].fiber_work_status}</td>
                                    <td width="25%" style={{backgroundColor :'white'}}>{date}</td>
                                </tr>
                            </table>
                            <div className="rButton" onClick = {() => navigate('/', {replace: true})}>Nuova ricerca</div>
                        
                        </div>
                    </div>
                )
            }
            else{
                let date;
                if(OF.result){
                    date = OF.result[0].piano_of_fibra;
                    if(date === "") date = "Data non definita"
                }
                if(OF.of.length === 1000) alert("La ricerca OpenFiber ha prodotto più di 1000 risultati, non sono stati caricati tutti, se vuoi un indirizzo specifico ricercalo direttamente.")
                setStatus(
                    <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
                        {
                            (response.data.status === "civic_connected" && FC.COPERTURA_BITSTREAM_NGA_VULA_FTTH !== "Attivo" && OF.of.length === 0) && 
                            <div className='barlow' style={{padding: '5px', width: "95%", backgroundColor: 'white', margin: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '10px', borderRadius: "13px", textAlign: 'center'}}>
                                <h2 onMouseOver={() => console.log("Uffa :(")} style={{width: '95%'}}>Sembrerebbe che tu non sia raggiunto dalla rete <span style={{color: "#ff0f64"}}>FTTH</span> da nessuno dei nostri operatori di rete, è davvero un peccato.</h2>
                                <h2 style={{color: '#5f6a6a'}}>Vuoi essere contattato in caso il tuo indirizzo venga raggiunto in futuro?</h2>
                                <div className="rButton" onClick = {() => navigate('/contactme', {replace: true})} style={{backgroundColor: "#ff0f64", color: 'white', borderRadius: '10px', height: '30px', fontWeight: 'bold'}}>
                                    <p>Invia richiesta</p>
                                </div>
                            </div>
                        }
                        <div style={{margin: 'auto', width: '95%'}}>
                            <div className="rButton" onClick = {() => navigate('/', {replace: true})}>
                                <p>Nuova ricerca</p>
                                <img src={Lens} alt="lens" style={{marginLeft: '2px'}}/>
                            </div>
                        </div>
                        <Status status={response.data.status} FC={FC} OF={OF.status}/>
                        {(FC.message !== 'unavailable' && response.data.status !== 'city_connected' && response.data.status !== 'fc_not_aviable') && <GeneralData data={FC}/>}
                        {
                            
                            OF.of.length > 0 ? <>
                                <div style={{width: '95%', backgroundColor: 'white', margin: 'auto', borderRadius: '13px', display: 'block', marginBottom: '30px'}}>
                                    <p style={{color: '#ff0f64', fontSize: '34px', marginLeft: '20px', fontWeight: '700'}} className="barlow">Copertura OpenFiber</p>
                                    {(OF.status === 'civic_connected' || OF.status === 'city_connected') && <Status status='civic_connected_of' />}
                                    {OF.status === 'something_missing' && <Status status={OF.status} code={OF.code} data={OF}/>}
                                    {OF.status === 'pac/pal' && <Status status={"pac/pal"} code={OF.code} data={OF}/>}
                                    <Maps locations={OF.of}/>
                                    <CoveredList params={{city, address,egon, number, cluster: OF.cluster}}/>
                                    <p style={{color: '#5f6a6a', marginTop: '4px', fontSize: '20px', marginBottom: '20px', textAlign: 'center'}}>Le informazioni sono state prese dal DB di OpenFiber dal cluster <span style={{color: '#00FF00'}}>{OF.cluster}</span></p>
                                </div>
                            </> : 
                            <>
                                <div style={{width: '95%', backgroundColor: 'white', margin: 'auto', borderRadius: '13px', marginBottom: '30px', display: 'flex', flexDirection: 'column'}}>
                                    <p style={{color: '#ff0f64', fontSize: '34px', marginLeft: '20px', fontWeight: '700'}} className="barlow">Copertura OpenFiber</p>
                                    <Status status={'not_connected'} />
                                    <br />
                                    {OF.result && <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                                        <h2>Previsioni</h2>
                                        <table className='cityStatusTable'>
                                            <tbody>
                                                <tr className="tPar" style={{color: 'white'}}>
                                                    <th>Comune</th>
                                                    <th>Stato lavori fibra</th>
                                                    <th>Data</th>
                                                </tr>
                                                <tr >
                                                    <td width="25%" style={{backgroundColor :'white'}}>{OF.result[0].city_name}</td>
                                                    <td width="50%" style={{backgroundColor :'white'}}>{OF.result[0].fiber_work_status}</td>
                                                    <td width="25%" style={{backgroundColor :'white'}}>{date}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>}
                                </div>
                            </>
                        }
                        {Fibre_Connect.status ? <Status status="fibre_connect_aviable"/> : <></>}
                        
                        <div className="rButton center" onClick = {() => navigate('/', {replace: true})}>
                            <p>Nuova ricerca</p>
                            <img src={Lens} alt="lens" style={{marginLeft: '2px'}}/>
                        </div>
                                       
                    </div>
                )
            }
            if (response.data.status === "civic_connected")
                FormsetStatus(
                    <Form city={city} address={address} number={number} />
                )
        })
    } 
    const SetInput = () =>{
        if(isLoading) return alert("Attendi che la ricerca attuale sia completata.");
        let cityValue = document.getElementById("cname").value;
        let numberValue = document.getElementById("number").value;
        /*if (document.getElementById("address").value === "" || document.getElementById("address").value === "NON DEFINITO"){
            egon = ""
            address = "NON DEFINITO";
        }*/
        if (numberValue === "NON DEFINITO") numberValue = "";
        if (!isEmpty(address) && numberValue === ""){
            alert("Puoi cercare un intero comune o un civico specifico")
            return
        }
        if (isEmpty(address) && numberValue !== ""){
            alert("Devi inserire anche l'indirizzo")
            return
        }
        if (cityValue === "")
        {
            alert("Devi inserire almeno il comune")
            return;
        }
        setIsLoading(true);
        setStatus(
            <div style={{height: '60vh'} }>
                <Loading style={{width: '95%', marginTop: '30px', height: 'calc(100% - 30px)', borderRadius: '13px'}}/>
            </div>
        )
        dispatch(changeCity(cityValue))
        dispatch(changeNumber(numberValue))
        FormsetStatus(<></>)
        loadResults(cityValue,address,numberValue,egon)
    }
    let civico = number
    if(civico === "") civico = "NON DEFINITO";
    if(city==="") city = "NON DEFINITO";
    if(address ==="") address = "NON DEFINITO";   
    return(
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%', justifyContent: 'space-between' }}>
            <Navbar />
            <div style={{width: '100%', height: '100%'}}>
                <div className="rDataContainer">
                    <div className="rDataFrame">
                        Comune
                        <FreeSoloResults label="Comune" classes={ResulTextFStyle()} value={city}/>
                    </div>
                    <div className="rDataFrame">
                        Indirizzo
                        <FreeSoloResults label="Indirizzo" classes={ResulTextFStyle()} value={address}/>                        
                    </div>
                    <div className="rDataFrame">
                        Civico
                        <FreeSoloResults label="Civico" classes={ResulTextFStyle()} value={civico}/>    
                    </div>
                    <div className='resultResearchButton' onClick = {() => {SetInput()} }>
                        <p style={{marginBottom: '5px'}}>Cerca</p>
                    </div>
                     
                </div>
                {status}
                {Formstatus}
            </div> 
            
            <Cookies />
        </div>
    )
    

}




export default Results;