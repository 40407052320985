import React, { useEffect, useState } from 'react';
import '../Layout Sito/style.css';
import './styles/Top.css';
import FreeSolo2 from './FreeSolo2';
import Button from './Button'
import Cookie from './Cookies';
import Navbar from './Navbar';
import ConnectionFinder from '../api/ConnectionFinder';
import Info from '../img/info.png';
import TextFStyle from './TextFStyle';
import Tooltip from '@mui/material/Tooltip';
import VisitCookie from './VisitCookie';

function Home(props){
    const [date, setDate ]= useState();
    const filled = props.filled;
    const setFilled = props.f;
    useEffect(() => {
        window.scrollTo(0, 0);
        ConnectionFinder.get('/dbStatus').then((response) => {
            setDate(response.data.update)
        })
        VisitCookie();
    }, [])

    return(
        <div className='Rcontex' style={{width: '100%', height: '100%', overflow: 'auto'}}>
            <Navbar /> 
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', height: '100%'}}>
                <div className="context" style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', marginBottom: '2%'}}>
                    <form className="form" method="post">
                        <p className="title" >Ricerca copertura</p>
                        <FreeSolo2 label="Comune" filled={filled} classes={TextFStyle()} value={""}/>
                        <FreeSolo2 label="Indirizzo" filled={filled} classes={TextFStyle()} value={""}/>
                        <FreeSolo2 label="Civico" classes={TextFStyle()} value={""}/>
                        <Button f={() => setFilled()} filled={filled} />
                        <div style={{display: 'flex'}}> 
                            <p style={{"color":"grey", fontSize: '18px'}}>Dati aggiornati in data <span style={{color: '#ff0f64', fontWeight: '400'}}>{" "+date}</span>. </p> 
                            <Tooltip enterTouchDelay={0} title={<p style={{fontSize: '15px', padding: '2px'}}>L'aggiornamento viene effettuato una volta a settimana prendendo i dati più recenti a quel momento.</p>}><img src={Info} alt='' style={{width: '25px', height: '25px', marginLeft: '2px',userSelect: 'none'}} /></Tooltip>
                        </div>
                    </form>
                    <Cookie />
                </div>
                {/* <AdComponent /> */} 
            </div>
        </div>
        
    )
}

export default Home;