import { TextField} from "@material-ui/core"

import './styles/Form.css';
import './styles/Results.css';
import { useRef, useState } from "react";
import ConnectionFinder from "../api/ConnectionFinder";
import MenuItem from '@mui/material/MenuItem';
import PrivacyPdf from "./Documents/privacy_tlc.pdf";
import { makeStyles } from "@material-ui/core/styles";
const Form = (prop) => {
  const useStyles = makeStyles((theme) => ({
      root: {
        "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
          // Default transform is "translate(14px, 20px) scale(1)""
          // This lines up the label with the initial cursor position in the input
          // after changing its padding-left.
          transform: "translate(34px, 20px) scale(0.9);",
        },
        "& label.Mui-focused": {
          color: "grey"
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "grey"
          }
        },
        '& .MuiFormHelperText-root': {
          position: 'absolute',
          top: '100%'
        },
        "&.Mui-focused .MuiInputLabel-outlined": {
          color: "grey"
        },
        "& .MuiInputLabel-outlined": {
          fontSize: "18px",
          
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#ff0f64",
          borderRadius: "15px",
          borderWidth: "3px",
          fontSize: "17px",
        },
  
        // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
  
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#ff0f64",
          borderRadius: "10px",
          borderWidth: "2px",
  
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "grey",
          borderRadius: "10px",
          borderWidth: "1px"
        },
        "&.Mui-checked .MuiOutlinedInput-notchedOutline": {
          borderColor: "grey",
          borderRadius: "10px",
          borderWidth: "2px",
          color: "red"
          
        },
        "& .MuiFilledInput-underline::after": {
          borderColor: "#ff0f64",
        },
        "& .MuiInputBase-root": {
        
          width: '18vw'

        },
        "@media screen and (min-width: 700px ) and  (max-width: 900px)":
        {
          "& .MuiInputBase-root": {
            width:'40vw'
          }
        },
        "@media screen and (max-width: 700px )":
        {
          "& .MuiInputBase-root": {
            width:'70vw'
          }
        }
      }
  }));
  
  
  const Classes = useStyles();
  const form = useRef();
  const sendEmail = (e, a) => {
    e.preventDefault();
    ConnectionFinder.post('form/offers',{
      mail: form.current[6].value,
      name: form.current[0].value,
      surname: form.current[2].value,
      fnumber: form.current[8].value,
      Note: form.current[14].value,
      ragsoc: form.current[4].value,
      Interesse: form.current[10].value,
      Tel: form.current[12].value,
      city: prop.city,
      address: prop.address,
      number: prop.number,
      
    }).then((response) =>{
      if(response.data){
        usestate(
          <div style={{width: '95%', backgroundColor: 'white', margin: 'auto', borderRadius: '13px', marginBottom: '10px', display: 'flex', flexDirection: 'column', marginTop: '20px',textAlign:'center',minHeight: '530px',justifyContent: 'center'}}>
            <p className="title">Richiesta inviata  con succeso</p>
          </div>
      )}
      else{
        usestate(
          <div style={{width: '95%', backgroundColor: 'white', margin: 'auto', borderRadius: '13px', marginBottom: '10px', display: 'flex', flexDirection: 'column', marginTop: '20px',textAlign:'center',minHeight: '530px',justifyContent: 'center'}}>
            <p className="title">Qualcosa è andato Storto</p>
            <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '96%', margin: 'auto',marginTop:'10px',marginBottom:'10px'}}> 
              Ritentare più tardi
            </h2>
          </div>
        )
      }
    })
  
  };
  const reset = (e) =>{
    e.target.setCustomValidity('')
  }
  
  const [state,usestate] = useState(
    <div id="form" style={{width: '95%', backgroundColor: 'white', margin: 'auto', borderRadius: '13px', marginBottom: '10px', display: 'flex', flexDirection: 'column', marginTop: '20px',minHeight: '530px'}}>
      <p style={{color: '#ff0f64', fontSize: '34px', marginLeft: '20px', fontWeight: '700'}} className="barlow">Richiesta offerta personalizzata</p>
      <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '96%', margin: 'auto',marginTop:'10px',marginBottom:'10px'}}> 
        Compilando il form si verrà ricontattati da un nostro consulente oppure, qualora le informazioni fossero sufficienti, 
        si riceverà direttamente un’offerta personalizzata gratis e senza impegno.
      </h2>
        <form className="form1" onSubmit={sendEmail} ref={form}>
            <TextField label="Nome" margin="normal" variant="outlined" type="text" classes={Classes} name="Nome" inputProps={{maxLength: '60'}} />
            <TextField label="Cognome" margin="normal" variant="outlined" type="text" classes={Classes} name="Cognome" inputProps={{maxLength: '60'}} />
            <TextField label="Ragione Sociale" margin="normal" variant="outlined" classes={Classes} name="RagSoc" inputProps={{maxLength: '60'}} helperText="(Solo Aziende)"/> 
            <TextField label="E-mail" margin="normal" variant="outlined" required classes={Classes} name="Email" type="email" inputProps={{maxLength: '60'}}  onInvalid={(e) => {e.target.setCustomValidity("inserire un'e-mail valida")}} onInput={reset}/>
            <TextField label="Numero di Telefono" margin="normal" variant="outlined" classes={Classes} name="Numero di Telefono" type="number" inputProps={{maxLength: '60'}}/>
            <TextField label="Sarei interessato a" margin="normal" select variant="outlined" classes={Classes} name="Interesse" defaultValue={""} >
              <MenuItem value={"Nuova Attivazione"} key={"Nuova Attivazione"}>Nuova Attivazione</MenuItem>            
              <MenuItem value={"Migrazione"} key={"Migrazione"}>Migrazione</MenuItem>            
            </TextField> 
            <TextField label="Telefono Fisso?" margin="normal" select variant="outlined" classes={Classes} name="Tel" defaultValue={""} >
              <MenuItem value={"Si"} key={"Y"}>Sì</MenuItem>            
              <MenuItem value={"No"} key={"N"}>No</MenuItem>    
            </TextField> 
            <TextField label="Note" margin="normal" variant="outlined" name="Note" multiline={true} maxRows={3} classes={Classes} inputProps={{maxLength: '500'}} id="Note"/>     
            <TextField label="Città" margin="normal" variant="filled" classes={Classes} name="Citta" defaultValue={prop.city} inputProps={{readOnly: true}}/>
            <TextField label="Indirizzo" margin="normal" variant="filled" classes={Classes} name="Indirizzo" defaultValue={prop.address} inputProps={{readOnly: true}} />
            <TextField label="N. Civ." margin="normal" variant="filled" classes={Classes} name="NCiv" value={prop.number} inputProps={{readOnly: true}}/>
            <div className="privacy" style={{marginTop: '10px', display: 'flex', width:'90%', justifyContent: 'center', marginBottom: '15px'}}>
              <input type="checkbox" name="ftth" id="cftth" style={{accentColor: "#ff0f64", transform: "scale(1.5)"}} required/>
              <label htmlFor="ftth" style={{fontSize: "15px",verticalAlign:'top',marginRight: '5px', marginLeft: '10px' ,textAlign: 'justify'}}>Dichiaro di aver letto l'<a href={PrivacyPdf}>Informativa sulla Privacy</a> e di accettare il trattamento dei dati personali ai sensi del GDPR UE 2016/679</label>
            </div>
            <input type="submit" value="Invia" className="sub" style={{borderRadius: '1'}}></input>
        </form>
    </div>
  );
  return (
    <div>
      {state}
    </div>

      
    
  )
}
export default Form
