import { TextField} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";
import './styles/Form.css';
import './styles/Results.css';
import { useRef, useState, useEffect} from "react";
import ConnectionFinder from "../api/ConnectionFinder";
import Navbar from "./Navbar";
import { useSelector} from 'react-redux';
import PrivacyPdf from "./Documents/privacy_tlc.pdf";
import { useNavigate } from "react-router";


const Contactme = () =>{

    let city = useSelector(state => state.params.city)
    let addrinfo = useSelector(state => state.params.address)
    let address = addrinfo.name;
    let egon = addrinfo.egon;
    let fctable = addrinfo.fctable;
    let number = useSelector(state => state.params.civic)
    const navigate = useNavigate();

    useEffect(() => {
        if(fctable === undefined || number === undefined || number === '' || fctable === '') return(navigate("/"))
    }, [fctable, navigate, number])

    const useStyles = makeStyles((theme) => ({
        root: {
        "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
            // Default transform is "translate(14px, 20px) scale(1)""
            // This lines up the label with the initial cursor position in the input
            // after changing its padding-left.
            transform: "translate(34px, 20px) scale(0.9);",
        },
        "& label.Mui-focused": {
            color: "grey"
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
            borderColor: "grey"
            }
        },
        "&.Mui-focused .MuiInputLabel-outlined": {
            color: "grey"
        },
        "& .MuiInputLabel-outlined": {
            fontSize: "18px",
            
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ff0f64",
            borderRadius: "15px",
            borderWidth: "3px",
            fontSize: "17px",
        },
        '& .MuiFormHelperText-root': {
            position: 'absolute',
            top: '100%'
          },
        // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ff0f64",
            borderRadius: "10px",
            borderWidth: "2px",

        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderRadius: "10px",
            borderWidth: "1px"
        },
        "&.Mui-checked .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderRadius: "10px",
            borderWidth: "2px",
            color: "red"
            
        },
        "& .MuiFilledInput-underline::after": {
            borderColor: "#ff0f64",
        },
        "& .MuiInputBase-root": {
            
            height: '60px',
            width: '19vw'
            
        },
        "@media screen and (min-width: 700px ) and  (max-width: 900px)":
        {
            "& .MuiInputBase-root": {
            height: '60px',
            width:'40vw'
            }
        },
        "@media screen and (max-width: 700px )":
        {
            "& .MuiInputBase-root": {
            height: '60px',
            width:'70vw'
            }
        }
        }
    }));
    
    const Classes = useStyles()
    let form = useRef();
    
    const reset = (e) =>{
        e.target.setCustomValidity('')
    }

    const DbIns = (e) =>{
        e.preventDefault()
        ConnectionFinder.post('form/contactme',{
            mail: form.current[6].value,
            name: form.current[0].value,
            surname: form.current[2].value,
            city,
            address, 
            number,
            ragsoc: form.current[4].value,
            fnumber: form.current[8].value,
            egon,
            fctable
          }).then((response) => {
            usestate(
                    <>
                        <Navbar/>
                        <div id="Submitted" style={{width: '95%', backgroundColor: 'white', margin: 'auto', borderRadius: '13px', marginBottom: '10px', display: 'flex', flexDirection: 'column', marginTop: '20px',textAlign:'center',justifyContent: 'center'}}>
                            <p className="title">Ti Contatteremo</p>
                            <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '96%', margin: 'auto',marginTop:'10px',marginBottom:'10px'}}> 
                                Verificheremo periodicamente il tuo indirizzo, e ti informeremo tempestivamente qualora fossi raggiunto dalla fibra FTTH
                            </h2>
                        </div>
                    </>
                )
            })
    }
    
    const [state,usestate] = useState(
        <>
        <Navbar />
            <div id="PROVA" style={{width: '95%', backgroundColor: 'white', margin: 'auto', borderRadius: '13px', marginBottom: '10px', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                <div>
                    <p style={{color: '#ff0f64', fontSize: '34px', marginLeft: '20px', fontWeight: '700'}} className="barlow">Aggiornami</p>
                    <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '96%', margin: 'auto',marginTop:'10px',marginBottom:'10px'}}> 
                        Compilando il form verificheremo periodicamente il tuo indirizzo in maniera automatizzata e ti informeremo in caso di arrivo della fibra FTTH
                    </h2>
                </div>
                <form className="form1" onSubmit={DbIns} ref={form} id="contactme"  >
                    <TextField label="Nome" margin="normal" variant="outlined" type="text" classes={Classes} name="Nome" />
                    <TextField label="Cognome" margin="normal" variant="outlined" type="text" classes={Classes} name="Cognome" />
                    <TextField label="Ragione Sociale" margin="normal" variant="outlined" classes={Classes} name="RagSoc" inputProps={{maxLength: '60'}} helperText="(Solo Aziende)"/> 
                    <TextField label="E-mail" margin="normal" variant="outlined" required classes={Classes} name="Email" type="email"  onInvalid={(e) => {e.target.setCustomValidity("inserire un'e-mail valida")}} onInput={reset}/>
                    <TextField label="Numero di Telefono" margin="normal" variant="outlined" classes={Classes} name="Numero di Telefono" type="number" inputProps={{maxLength: '60'}} />
                    <TextField label="Città" margin="normal" variant="filled" classes={Classes} name="Citta"  value={city}inputProps={{readOnly: true}}/>
                    <TextField label="Indirizzo" margin="normal" variant="filled" classes={Classes} name="Indirizzo" value={address} inputProps={{readOnly: true}} />
                    <TextField label="N. Civ." margin="normal" variant="filled" classes={Classes} name="NCiv" value={number} inputProps={{readOnly: true}}/>
                    <div className="privacy" style={{display: 'flex',alignItems: 'center'}}>
                        <input type="checkbox" name="ftth" id="cftth" style={{marginLeft: '15px', accentColor: "#ff0f64", transform: "scale(1.5)",verticalAlign: 'top'}} required/>
                        <label htmlFor="ftth" style={{fontSize: "15px",verticalAlign:'top',marginRight: '10px', marginLeft: '10px'}}>Dichiaro di aver letto l'<a href={PrivacyPdf}>Informativa sulla Privacy</a> e di accettare il trattamento dei dati personali ai sensi del GDPR UE 2016/679</label>
                    </div>
                    <input type="submit" value="Invia" className="sub" id="subContact" style={{borderRadius: '1'}}></input>
                </form>
            </div>
        </>
    );

    return(
        <>
            {state}
        </>
    )



}
export default Contactme;