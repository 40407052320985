import React, { useEffect, useState } from "react";
import ConnectionFinder from "../api/ConnectionFinder";
import Tooltip from '@mui/material/Tooltip';
import mIcon from '../img/maps.svg';
import previous from '../img/previous.png';
import next from '../img/next.png';
import { useDispatch } from 'react-redux'
import { changeAddress, changeNumber } from '../actions';



const CoveredList = (props) => {

    const params = props.params

    const [cList, setCList] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1000);

    const dispatch = useDispatch();

    const research = async (address, egon, civic) => {
        await dispatch(changeAddress({egon, name: address}))
        await dispatch(changeNumber(civic))
        window.location.reload()
    }

    const setPage = (sum) => {
        if(currentPage + sum <= 0 || currentPage + sum > maxPage) return;
        setCurrentPage(currentPage + sum)
    }

    const getNearList = async () => {
        await ConnectionFinder.get('/getNearList', {
            params: {
                ...params,
                page: currentPage
            }
        }).then((response) => {
            setMaxPage(response.data.maxPage)
            setCList(
                <div className="coveredListContainer">
                    <div className="coveredListTitle">
                        <p className='clText'>Altri civici</p>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <p style={{fontSize: '20px', color: '#5f6a6a', fontWeight: 'bold'}}>Stati: </p>
                            <div style={{backgroundColor: '#2ECC71', color: 'white', padding: '5px', paddingTop: '1px', paddingBottom: '1px', borderRadius: '5px', marginLeft: '5px', fontSize: '20px', textAlign:'center', width: '100px'}}>Connesso</div>
                            <div style={{backgroundColor: '#F1C40F', color: 'white', padding: '5px', paddingTop: '1px', paddingBottom: '1px', borderRadius: '5px', marginLeft: '5px', fontSize: '20px', textAlign:'center', width: '100px'}}>Problemi</div>
                        </div>
                    </div>
                    <ul className="coveredListContainerScroll">
                        {response.data.rows.map((item) => {
                            let sColor = '#2ECC71'
                            let sText = 'Connesso'
                            if(item.STATO_UI !== '102' && item.STATO_UI !== '104' && item.STATO_UI !== '105' && item.STATO_UI !== '110' && item.STATO_UI !== '302') {
                                sColor = '#F1C40F';
                                sText = 'Problemi'
                            }
                            if(item.COORDINATE_BUILDING === "N_E"){
                                return (
                                    <li className="coverItem" key={item.ID_SCALA}>
                                        <div className="coverItemDatas">
                                            <span onClick={() => research(item.PARTICELLA_TOP+" "+item.INDIRIZZO, item.ID_EGON_STRADA.trim(), item.CIVICO)} style={{cursor: 'pointer'}}>
                                                {item.PARTICELLA_TOP} {item.INDIRIZZO} {item.CIVICO} {item.SCALA_PALAZZINA}
                                            </span>
                                            <Tooltip  title={sText}>
                                                <span style={{backgroundColor: sColor, color: 'white', padding: '5px', paddingTop: '1px', paddingBottom: '1px', borderRadius: '5px', marginLeft: '5px', fontSize: '20px'}} >{item.STATO_UI}</span>
                                            </Tooltip >
                                        </div>
                                    </li>
                                )
                            }else{
                                return (
                                    <li className="coverItem" key={item.ID_SCALA}>
                                        <div className="coverItemDatas" >
                                            <span onClick={() => research(item.PARTICELLA_TOP+" "+item.INDIRIZZO, item.ID_EGON_STRADA.trim(), item.CIVICO)} style={{cursor: 'pointer'}}>
                                                {item.PARTICELLA_TOP} {item.INDIRIZZO} {item.CIVICO} {item.SCALA_PALAZZINA}
                                            </span>
                                            <Tooltip  title={sText}>
                                                <span style={{backgroundColor: sColor, color: 'white', padding: '5px', paddingTop: '1px', paddingBottom: '1px', borderRadius: '5px', marginLeft: '5px', fontSize: '20px'}} >{item.STATO_UI}</span>
                                            </Tooltip >
                                        </div>
                                        <a className="coverItemLocation" target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${item.COORDINATE_BUILDING.replace("_","")}`}>
                                            <img src={mIcon} alt=""  width="30px" height="30px"/>
                                        </a>                                                
                                    </li>
                                )
                            }
                        })}
                    </ul>
                    <div style={{widht: '100%', display: 'flex', height: '60px', justifyContent: 'space-between'}}>
                        {currentPage !== 1 && <div onClick={() => setPage(-1)} style={{width: '40%', backgroundColor: '#ff0f64', color: 'white', height: '10px' ,borderRadius: '13px', transform: 'none',margin: 'auto', marginLeft: '2%'}} className="rButton">
                            <img src={previous} alt="" width={'25px'} height={'25px'} />
                        </div> }  
                        {console.log(response.data.rows.length)}         
                        {currentPage < maxPage && response.data.maxPage > 1 && <div onClick={() => setPage(1) } style={{width: '40%', backgroundColor: '#ff0f64', color: 'white', height: '10px' ,borderRadius: '13px', margin: 'auto',marginRight: '2%', transform: 'none'}} className="rButton">
                            <img src={next} alt="" width={'25px'} height={'25px'}/>
                        </div>}
                    </div>
                </div>  
            )
        }) 
    }

    

    useEffect(() => {
        getNearList()
        // eslint-disable-next-line
    }, [currentPage])

    return(
        <div>
            {cList}
        </div>
    )
}

export default CoveredList;